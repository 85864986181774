import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes, FeatureFlagged } from '@backstage/core-app-api';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';

import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { UnifiedThemeProvider } from '@backstage/theme';
import {
  CatalogIndexPage as CustomCatalogPage,
  xelerateCatalogPlugin,
} from '@internal/plugin-xelerate-catalog';
import DarkIcon from '@mui/icons-material/Brightness2';
import LightIcon from '@mui/icons-material/WbSunny';
// commented old announcement
// import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
// new announcement
import {
  AnnouncementsPage,
  AnnouncementsAdminPortal,
} from '@internal/backstage-plugin-announcements';
import { ErrorPage } from '@xlrt/components';
import React from 'react';
import { Route } from 'react-router';
import { apis, cloudIdpAuthApiRef } from './apis';
import { CatalogImportPage } from './components/catalog-import/CatalogImportPage';
import { entityPage } from './components/catalog/EntityPage';
import { DxpReaderPage } from './components/customDocsReader/dxpReaderPage';
import { InlineSwaggerUiExtension } from './components/customDocsReader/inlineSwaggerUiExtension';
import { ReaderStylesExtension } from './components/customDocsReader/readerStylesExtension';
import { icons } from './components/Icons/icons';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import {
  customUnifiedDarkTheme,
  customUnifiedLightTheme,
} from './components/theme/cariad-theme';
import SignInPage from './components/signin/SignInPage';
import AboutPage from './components/about/AboutPage';
import PrivacyPolicyPage from './components/policy-pages/PrivacyPolicyPage';
import CookiePolicyPage from './components/policy-pages/CookiePolicyPage';
import { Dashboard } from './components/dashboard/Dashboard';
import { settingsPage } from './components/user-settings';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { AscDisplayPage } from '@cariad-dxp/apim-plugin-asc-react';
import { GroupYamlGeneratorPage } from '@internal/backstage-plugin-group-yaml-generator';
import { iconMapping } from '@internal/backstage-plugin-common-ui-react';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { CatalogVer2Page } from '@internal/backstage-plugin-catalog-ver-2';
import { CatalogYamlGeneratorPage } from '@internal/backstage-plugin-catalog-yaml-generator';
import { announcementAdministerPermission } from '@internal/backstage-plugin-announcements-common';

import { SignalsDisplay } from '@backstage/plugin-signals';

import { QetaPage } from '@drodil/backstage-plugin-qeta';

const app = createApp({
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'cloudidp-provider',
          title: 'CARIAD CloudIDP',
          message: 'Sign in using Cloud IDP credentials',
          apiRef: cloudIdpAuthApiRef,
        }}
      />
    ),
    NotFoundErrorPage: props => (
      <ErrorPage {...props} status="404" statusMessage="PAGE NOT FOUND" />
    ),
    ErrorBoundaryFallback: props => (
      <ErrorPage {...props} status="400" statusMessage="SOMETHING WENT WRONG" />
    ),
  },
  apis,
  featureFlags: [
    {
      // pluginId is required for feature flags used in plugins.
      // pluginId can be left blank for a feature flag used in the application and not in plugins.
      pluginId: '',
      name: 'disable-new-catalog',
      description:
        'Toggle between new and old catalog view. New catalog view enabled by default.',
    },
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: xelerateCatalogPlugin.routes.root,
    });
  },
  icons: {
    ...icons,
    ...iconMapping,
  },
  themes: [
    {
      id: 'custom-light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedLightTheme}
          children={children}
        />
      ),
    },
    {
      id: 'custom-dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedDarkTheme}
          children={children}
        />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    {/* Public Routes */}
    <Route path="/" element={<HomepageCompositionRoot />}>
      <Dashboard />
    </Route>
    <Route path="/about" element={<AboutPage />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage>{DxpReaderPage}</TechDocsReaderPage>}
    >
      <TechDocsAddons>
        <ReportIssue />
        <ReaderStylesExtension />
        <InlineSwaggerUiExtension />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />

    <Route path="/catalog" element={<CatalogIndexPage />}>
      <FeatureFlagged with="disable-new-catalog">
        <CustomCatalogPage />
      </FeatureFlagged>
      <FeatureFlagged without="disable-new-catalog">
        <CatalogVer2Page />
      </FeatureFlagged>
    </Route>

    <Route
      path="/announcements/admin"
      element={
        <RequirePermission permission={announcementAdministerPermission}>
          <AnnouncementsAdminPortal
            title="Xelerate Announcement Portal"
            subtitle="Xelerate Admin Portal for Managing Announcements"
            themeId="home"
          />
        </RequirePermission>
      }
    />
    <Route
      path="/announcements"
      element={
        <AnnouncementsPage
          hideContextMenu
          buttonOptions={{ name: 'Update' }}
          title="Updates"
        />
      }
    />

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      {settingsPage}
    </Route>
    {/* Restricted Routes (should require permission) */}
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    <Route path="/cookie-policy" element={<CookiePolicyPage />} />
    <Route path="/quality" element={<AscDisplayPage />} />
    <Route path="/create-group" element={<GroupYamlGeneratorPage />} />
    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/create-catalog" element={<CatalogYamlGeneratorPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/qeta" element={<QetaPage title="Q&A" />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <SignalsDisplay />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
